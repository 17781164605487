import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

export const portfolioFeatures: SubscriptionFeature[] = [
    SubscriptionFeature.exportPortfolios,
    SubscriptionFeature.PortfolioSecuritySearch,
    SubscriptionFeature.PortfolioSavedFilters,
    SubscriptionFeature.PortfolioAvancedFilters,
    SubscriptionFeature.PortfolioColumnCustomizer,
    SubscriptionFeature.PortfolioPriceTalks,
    SubscriptionFeature.PortfolioAlerts
];
