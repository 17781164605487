export enum ColumnConfigKey {
    IsinCusip = 'IsinCusip',
    Ticker = 'Ticker',
    Inventory = 'Inventory',
    Asset = 'Asset',
    Currency = 'Currency',
    Rating = 'Rating',
    BidSize = 'BidSize',
    Size = 'Size',
    PxTalk = 'PxTalk',
    Color = 'Color',
    DealName = 'DealName',
    CollateralManager = 'CollateralManager',
    ClosingDate = 'ClosingDate',
    NcEndYrs = 'NcEndYrs',
    RiEndYrs = 'RiEndYrs',
    NcEnd = 'NcEnd',
    OutOfNc = 'OutOfNc',
    RiEnd = 'RiEnd',
    OutOfRi = 'OutOfRi',
    Maturity = 'Maturity',
    Vintage = 'Vintage',
    Trustee = 'Trustee',
    AmrDeal = 'AmrDeal',
    StaticDeal = 'StaticDeal',
    EuCompliance = 'EuCompliance',
    Esg = 'Esg',
    CollateralType = 'CollateralType',
    Actions = 'Actions',
    LastUpdateDate = 'LastUpdateDate',
    Dealer = 'Dealer',
    Bid = 'Bid',
    Offer = 'Offer',
    CreditEnhancement = 'CreditEnhancement',
    BidDmBps = 'BidDmBps',
    OfferDmBps = 'OfferDmBps',
    OfferYield = 'OfferYield',
    Coupon = 'Coupon',
    WalYears = 'WalYears',
    Mvoc = 'Mvoc',
    Description = 'Description',
    Bwic = 'Bwic',
    CurrentBwic = 'CurrentBwic',
    LastTraded = 'LastTraded',
    EvalPriceTalk = 'KTalk',
    DealDocuments = 'DealDocuments'
}
