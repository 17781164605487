export enum SubscriptionFeature {
    // Portfolio product
    exportPortfolios = 'ExportPortfolios',
    PortfolioSecuritySearch = "PortfolioSecuritySearch",
    PortfolioSavedFilters = "PortfolioSavedFilters",
    PortfolioAvancedFilters = "PortfolioAvancedFilters",
    PortfolioColumnCustomizer = "PortfolioColumnCustomizer",
    PortfolioPriceTalks = "PortfolioPriceTalks",
    PortfolioAlerts = "PortfolioAlerts",

    // Dashboard product
    CanUseDashboardFilter = 'CanUseDashboardFilter',
    CanUseDashboardAlerts = 'CanUseDashboardAlerts',
    getBidAsDealerRequests = 'GetBidAsDealerRequests',
    getVantageData = 'GetVantageData', // TRACE HY/IG Volume
    getDtcSellingBuying = 'GetDtcSellingBuying', // TRACE Broker-dealer Net Flow
    getBwicPercentOfTrace = 'GetBwicPercentOfTrace', // IG/HY BWICs as %Trace
    getMedianTripleA = 'GetMedianTripleA', // AAA Spread Levels
    getDntRates = 'GetDntRates', // DNT % Monthly: Liquidity Barometer
    getSameDayBwics = 'GetSameDayBwics', // Same-Day Weekly Volume: Stability Barometer
    getColorRates = 'GetColorRates', // BWIC Color %: Transperancy Barometer
    getMostTradedTickers = 'GetMostTradedTickers', // Most Traded Tickers
    getPriceTalkDeviation = 'GetPriceTalkDeviation', // Px Talk Deviation from Color
    getSecurityBwicStatistics = 'GetSecurityBwicStatistics', // Security Lookup: Px Talk & Historical Color Dispersion
    getPriceLevels = 'GetPriceLevels', // Daily Price Ranges by Rating
    getTradingStats = 'GetTradingStats', // Daily Trade Analysis
    getBiggestMovers = 'GetBiggestMovers',
    viewIncludedToPortfolioInventory = 'ViewIncludedToPortfolioInventory',
    IssuanceSpreadsGraph = 'IssuanceSpreadsGraph',
    Top10ArrangersTable = 'Top10ArrangersTable',
    EsgAsPercentOfBslNewIssueGraph = 'EsgAsPercentOfBslNewIssueGraph',
    Top10TrusteesTable = 'Top10TrusteesTable',
    EuCompliantAsPercentOfBslGraph = 'EuCompliantAsPercentOfBslGraph',
    StaticDealsTable = 'StaticDealsTable',
    ShortAndLongDatedDealsGraph = 'ShortAndLongDatedDealsGraph',
    DebutManagersTable = 'DebutManagersTable',
    UsBslNewIssueWasGraph = 'UsBslNewIssueWasGraph',
    UsBslNewIssueWarfGraph = 'UsBslNewIssueWarfGraph',
    UsBslNewIssueDsGraph = 'UsBslNewIssueDsGraph',
    CanUseIssueTransactionChartFilter = "CanUseIssueTransactionChartFilter",
    CanUseTopIssuersChartFilter = "CanUseTopIssuersChartFilter",

    IssuanceMonitorFullAccess = 'IssuanceMonitorFullAccess',

    // Bwic Monitor Product
    exportAllBwics = 'ExportAllBwics',
    ExportIndividualBwic = 'ExportIndividualBwic',
    BwicMonitorSecuritySearch = "BwicMonitorSecuritySearch",
    BwicMonitorSavedFilters = "BwicMonitorSavedFilters",
    BwicMonitorAvancedFilters = "BwicMonitorAvancedFilters",
    BwicMonitorColumnCustomizer = "BwicMonitorColumnCustomizer",
    BwicMonitorHistoricalData = "BwicMonitorHistoricalData",
    BwicMonitorBwicsData = "BwicMonitorBwicsData",
    BwicMonitorSorting = "BwicMonitorSorting",
    BwicMonitorNavigation = "BwicMonitorNavigation",
    BwicMonitorAlerts = "BwicMonitorAlrts",
    BwicMonitorCanViewSummaryRow = "BwicMonitorCanViewSummaryRow",

    // Inventory Product
    ExportInventory = 'ExportInventory',
    InventorySecuritySearch = "InventorySecuritySearch",
    InventorySavedFilters = "InventorySavedFilters",
    InventoryAvancedFilters = "InventoryAvancedFilters",
    InventoryColumnCustomizer = "InventoryColumnCustomizer",
    InventoryHistoricalData = "InventoryHistoricalData",
    InventoryNavigation = "InventoryNavigation",
    InventoryBwicColumn = "InventoryBwicColumn",
    InventoryAlerts = "InventoryAlerts",
    InventoryCanViewSummaryRow = "InventoryCanViewSummaryRow",

    // Resources
    CloMarketTrands = 'CloMarketTrands',
    HistoricalWebinars = 'HistoricalWebinars',

    // K-Watch
    KWatchNews = 'KWatchNews',
    KWatchNewsFullNewsDetails = 'KWatchNewsFullNewsDetails', // TODO use current feature instead of general
    KWatchNewsNewsAttachments = 'KWatchNewsNewsAttachments', // TODO use current feature instead of general

    // Issuance Monitor
    IssuanceMonitorStandardizedDetailsTabs = 'IssuanceMonitorStandardizedDetailsTabs', // TODO use current feature instead of general
    IssuanceMonitorMarketingHistory = 'IssuanceMonitorMarketingHistory', // TODO use current feature instead of general
    IssuanceMonitorFiltersEmailAlerts = 'IssuanceMonitorFiltersEmailAlerts', // TODO use current feature instead of general
    IssuanceMonitorColumnsCustomizer = 'IssuanceMonitorColumnsCustomizer', // TODO use current feature instead of general
    IssuanceMonitorDealUpdateEmailAlerts = 'IssuanceMonitorDealUpdateEmailAlerts', // TODO use current feature instead of general
    IssuanceMonitorHistoricalData = 'IssuanceMonitorHistoricalData', // TODO use current feature instead of general
    IssuanceMonitorArrangerPipeline = 'IssuanceMonitorArrangerPipeline', // TODO use current feature instead of general
    IssuanceMonitorWeeklyMarketRecap = 'IssuanceMonitorWeeklyMarketRecap', // TODO use current feature instead of general
    IssuanceMonitorExport = 'IssuanceMonitorExport', // TODO use current feature instead of general
    IssuanceMonitorTransactionsEmailAlerts = 'IssuanceMonitorTransactionsEmailAlerts',

    // Manager Profile (CLO Managers)
    ManagerProfilePresentations = 'ManagerProfilePresentations', // TODO use current feature instead of general
    ManagerProfileScheduleMeeting = 'ManagerProfileScheduleMeeting', // TODO use current feature instead of general
    ManagerProfileManagerRelatedAlerts = 'ManagerProfileManagerRelatedAlerts', // TODO use current feature instead of general
    ManagerProfileWebinarVideo = 'ManagerProfileWebinarVideo', // TODO use current feature instead of general
    ManagerProfileIssuanceSpreadsGraph = 'ManagerProfileIssuanceSpreadsGraph', // TODO use current feature instead of general
    ManagerProfileDealsGraph = 'ManagerProfileDealsGraph', // TODO use current feature instead of general
    ManagerProfilePrimaryMarketData = 'ManagerProfilePrimaryMarketData', // TODO use current feature instead of general
    ManagerProfileSecondaryMarketData = 'ManagerProfileSecondaryMarketData', // TODO use current feature instead of general
    ManagerProfileContacts = 'ManagerProfileContacts', // TODO use current feature instead of general
    ManagerProfileDeals = 'ManagerProfileDeals',
    ManagerProfileAnalytics = 'ManagerProfileAnalytics',

    // Integrations
    IntegrationsBloomberg = 'IntegrationsBloomberg',
    IntegrationsIntex = 'IntegrationsIntex',
    IntegrationsValitana = 'IntegrationsValitana',

    // Dealer Profile (Banks)
    DealerProfileBankContacts = 'DealerProfileBankContacts',
    DealerProfileBankEvents = 'DealerProfileBankEvents',
    DealerProfileEmailAlerts = 'DealerProfileEmailAlerts',
    DealerProfileBanksBWICsPxTalk = 'DealerProfileBanksBWICsPxTalk',
    DealerProfileStatistics = 'DealerProfileStatistics',
    DealerProfilePieCharts = 'DealerProfilePieCharts',
    DealerProfileTransactionsCount = 'DealerProfileTransactionsCount',
    DealerProfileDeals = 'DealerProfileDeals',
    DealerProfileArrangerPipeline = 'DealerProfileArrangerPipeline',
    DealerProfileAnalytics = 'DealerProfileAnalytics',

    //DealDocuments
    CanAccessIndentureDealDocuments = 'CanAccessIndentureDealDocuments',
    CanAccessOMDealDocuments = 'CanAccessOMDealDocuments',
    CanAccessDistributionReportDealDocuments = 'CanAccessDistributionReportDealDocuments',
    CanAccessMonthlyReportDealDocuments = 'CanAccessMonthlyReportDealDocuments',
    CanAccessOtherDealDocuments = 'CanAccessOtherDealDocuments',
    CanAccessDealDocuments = 'CanAccessDealDocuments' // Common feature for all deal document types
}

export const subscriptionFeatureTitle: { [key in SubscriptionFeature]?: string } = {
    // Dashboard
    [SubscriptionFeature.getTradingStats]: "Daily Trade Analytics table",
    [SubscriptionFeature.getBiggestMovers]: "Biggest Movers table",
    [SubscriptionFeature.getDtcSellingBuying]: "Trace Broker-Dealer Net Flow graph",
    [SubscriptionFeature.getPriceLevels]: "Price Dispersion by Rating graph",
    [SubscriptionFeature.getVantageData]: "Trace Volume graph",
    [SubscriptionFeature.getMostTradedTickers]: "Most Traded Bonds table",
    [SubscriptionFeature.getBwicPercentOfTrace]: "BWICs as % of Trace graph",
    [SubscriptionFeature.getMedianTripleA]: "AAA Px Talk and Color Levels graph",
    [SubscriptionFeature.getDntRates]: "DNT %: Demand Barometer graph",
    [SubscriptionFeature.getColorRates]: "Color % in BWICs: Transparency Barometer graph",
    [SubscriptionFeature.getSameDayBwics]: "Same-Day BWICs %: Stability Barometer graph",
    [SubscriptionFeature.getPriceTalkDeviation]: "Px Talk Deviation from Color: Liquidity Barometer graph",
    [SubscriptionFeature.IssuanceSpreadsGraph]: "Issuance Spreads graph",
    [SubscriptionFeature.Top10ArrangersTable]: "Top-10 Arrangers table",
    [SubscriptionFeature.EsgAsPercentOfBslNewIssueGraph]: "ESG as % of BSL New Issue graph",
    [SubscriptionFeature.Top10TrusteesTable]: "Top-10 Trustees table",
    [SubscriptionFeature.EuCompliantAsPercentOfBslGraph]: "EU Compliant as % of BSL graph",
    [SubscriptionFeature.StaticDealsTable]: "Static Deals table",
    [SubscriptionFeature.ShortAndLongDatedDealsGraph]: "Short & Long-Dated Deals graph",
    [SubscriptionFeature.DebutManagersTable]: "Debut Managers table",
    [SubscriptionFeature.UsBslNewIssueWasGraph]: "US BSL New Issue WAS graph",
    [SubscriptionFeature.UsBslNewIssueWarfGraph]: "US BSL New Issue WARF graph",
    [SubscriptionFeature.UsBslNewIssueDsGraph]: "US BSL New Issue DS graph",

    // Portfolio
    [SubscriptionFeature.getSecurityBwicStatistics]: "Security Lookup: Price History (Px Talk & Color) graph",
    [SubscriptionFeature.PortfolioSecuritySearch]: "Search by security",
    [SubscriptionFeature.exportPortfolios]: "Portfolio export",
    [SubscriptionFeature.PortfolioSavedFilters]: "Saved filters",
    [SubscriptionFeature.PortfolioAvancedFilters]: "Advanced filters",
    [SubscriptionFeature.PortfolioColumnCustomizer]: "Column customizer",

    // BWIC Monitor
    [SubscriptionFeature.BwicMonitorSecuritySearch]: "Search by security",
    [SubscriptionFeature.exportAllBwics]: "BWIC export",
    [SubscriptionFeature.BwicMonitorSavedFilters]: "Saved filters",
    [SubscriptionFeature.BwicMonitorAvancedFilters]: "Advanced filters",
    [SubscriptionFeature.BwicMonitorColumnCustomizer]: "Column customizer",
    [SubscriptionFeature.BwicMonitorHistoricalData]: "Access to historical BWIC data",
    [SubscriptionFeature.BwicMonitorBwicsData]: "Full access to Px Talk/BWIC rules/BWIC log data", // ???
    [SubscriptionFeature.BwicMonitorSorting]: "BWIC sorting",
    [SubscriptionFeature.BwicMonitorNavigation]: "Navigation from Inventory/Portfolio/Issuance Monitor to see security on the BWIC Monitor page",

    // Inventory
    [SubscriptionFeature.InventorySecuritySearch]: "Search by security",
    [SubscriptionFeature.ExportInventory]: "BWIC export",
    [SubscriptionFeature.InventorySavedFilters]: "Saved filters",
    [SubscriptionFeature.InventoryAvancedFilters]: "Advanced filters",
    [SubscriptionFeature.InventoryColumnCustomizer]: "Column customizer",
    [SubscriptionFeature.InventoryHistoricalData]: "Access to historical Inventory data",
    [SubscriptionFeature.InventoryNavigation]: "Navigation from BWIC Monitor/Portfolio/Issuance Monitor to see security on the Inventory page",

    // Resources
    [SubscriptionFeature.CloMarketTrands]: "CLO market trends report",
    [SubscriptionFeature.HistoricalWebinars]: "Historical webinars",

    // K-Watch News
    [SubscriptionFeature.KWatchNewsFullNewsDetails]: "Full news details",
    [SubscriptionFeature.KWatchNewsNewsAttachments]: "News attachments",

    // Issuance MonitorIssuance Monitor
    [SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs]: "Full deal details in 4 standardized tabs",
    [SubscriptionFeature.IssuanceMonitorMarketingHistory]: "Deal marketing history with tracked changes",
    [SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]: "Filters and filter-based email alerts",
    [SubscriptionFeature.IssuanceMonitorColumnsCustomizer]: "Columns customizer",
    [SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts]: "Deal update email alerts",
    [SubscriptionFeature.IssuanceMonitorHistoricalData]: "7 Years of historical data: priced & closed deals",
    [SubscriptionFeature.IssuanceMonitorArrangerPipeline]: "Arranger pipeline",
    [SubscriptionFeature.IssuanceMonitorWeeklyMarketRecap]: "Weekly primary market recap",
    [SubscriptionFeature.IssuanceMonitorExport]: "Issuance Monitor export",

    // Manager Profile
    [SubscriptionFeature.ManagerProfilePresentations]: "Manager presentations",
    [SubscriptionFeature.ManagerProfileScheduleMeeting]: "Schedule a meeting with the manager",
    [SubscriptionFeature.ManagerProfileManagerRelatedAlerts]: "All types of manager-related alerts",
    [SubscriptionFeature.ManagerProfileWebinarVideo]: "Webinar video",
    [SubscriptionFeature.ManagerProfileIssuanceSpreadsGraph]: "Issuance Spreads graph",
    [SubscriptionFeature.ManagerProfileDealsGraph]: "Short & Long-Dated Deals graph",
    [SubscriptionFeature.ManagerProfilePrimaryMarketData]: "Links to primary market underlying data",
    [SubscriptionFeature.ManagerProfileSecondaryMarketData]: "Links to secondary market underlying data",
    [SubscriptionFeature.ManagerProfileContacts]: "Manager contacts",

    // Integrations
    [SubscriptionFeature.IntegrationsBloomberg]: 'Bloomberg',
    [SubscriptionFeature.IntegrationsIntex]: 'Intex',
    [SubscriptionFeature.IntegrationsValitana]: 'Valitana',

    //Dealer Profile
    [SubscriptionFeature.DealerProfileBankContacts]: 'Bank contacts',
    [SubscriptionFeature.DealerProfileBankEvents]: 'Bank events',
    [SubscriptionFeature.DealerProfileEmailAlerts]: 'All types of bank-related alerts',
    [SubscriptionFeature.DealerProfileBanksBWICsPxTalk]: 'Bank BWICs Px Talk',

    //Deal Documents
    [SubscriptionFeature.CanAccessIndentureDealDocuments]: 'Indenture',
    [SubscriptionFeature.CanAccessOMDealDocuments]: 'Offering Memorandum',
    [SubscriptionFeature.CanAccessDistributionReportDealDocuments]: 'Distribution Report',
    [SubscriptionFeature.CanAccessMonthlyReportDealDocuments]: 'Monthly Report',
    [SubscriptionFeature.CanAccessOtherDealDocuments]: 'Other Deal Documents'

};
