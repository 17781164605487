import { QueryStringArgumentSerializer } from './QueryStringArgumentSerializer';

export class MultipleValuesArgumentSerializer
    extends QueryStringArgumentSerializer<string[]> {
    serialize = (values: string[] | undefined) => {
        if (!values || !values.length) {
            return undefined;
        }

        return values
            .map(value => value && this.emptySpaceSymbol ? value.replace(" ", this.emptySpaceSymbol) : value)
            .map(value => encodeURIComponent(value))
            .join(',');
    }

    deserialize = (rawValue: string): void => {
        if (!rawValue) {
            return undefined;
        }

        const values = rawValue
            .split(',')
            .map(value => value.trim())
            .filter(value => value)
            .map(value => decodeURIComponent(value))
            .map(value => value && this.emptySpaceSymbol ? value.replace(this.emptySpaceSymbol, " ") : value)
            .filter(value => this.isAccepted(value));

        if (values.length && this.onDeserializeCallback) {
            this.onDeserializeCallback(values);
        }
    }
}
